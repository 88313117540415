import React from "react"

const Intro = () => {
  return (
    <div class="py-40">
      <div class=" w-4/6  mx-auto">
        <div class="lg:px-16 px-4">
          <span class="text-center lg:text-3xl text-2xl">
            Amazing stay in lake Kivu front view with unique taste of
            tranquility. 10 exclusive bungalows with 270° panoramic views of the
            lake Kivu and its landiscapes .
          </span>
        </div>

        <div class="grid grid-row-1 lg:grid-cols-2 grid-cols-1  gap-6 py-12 ">
          <div>
            <span class="text-lg">
              <b> Our Garden...</b> “Our Pride & Joy”! breathtaking soul
              stirring with exotic flowers,green lawns,impressive palm trees and
              luxurious vegetation.
            </span>
          </div>

          <div>
            <span class="text-lg">
              <b> Our Private Beach ... </b>peaceful and relaxing gives you
              opportunity to safely swimm in the lake or just sunbathe in an
              ambient of tranquility.
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Intro
