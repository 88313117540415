import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Gallery from "@browniebroke/gatsby-image-gallery"
import "@browniebroke/gatsby-image-gallery/dist/style.css"

const PropertyGallery = () => {
  const data = useStaticQuery(query)
  const images = data.images.edges.map(({ node }) => ({
    ...node.childImageSharp,
    // Use original name as caption.
    // The `originalName` is queried inside the `thumb` field,
    // but the `Gallery` component expects `caption` at the top level.
    // caption: node.childImageSharp.thumb.originalName,
    caption: "",
  }))

  const lightboxOptions = {
    imageLoadErrorMessage: "Impossible to load next image",
    nextLabel: "Next image",
    prevLabel: "Previous image",
    zoomInLabel: "Zoom in",
    zoomOutLabel: "Zoom out",
    closeLabel: "Close",
  }
  return (
    <div class="mt-20 px-4 lg:px-28 relative bg-white z-50 ">
      <Gallery images={images} lightboxOptions={lightboxOptions} />
    </div>
  )
}

export const query = graphql`
  query ImagesForGallery {
    images: allFile(
      filter: { relativeDirectory: { eq: "gallery" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 480, maxHeight: 480) {
              ...GatsbyImageSharpFluid
              originalName
            }
            full: fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default PropertyGallery
