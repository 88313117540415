import React from "react"

const TripAdvisorWidget = () => {
  return (
    <div className="px-4 lg:px-28 grid grid-row-1 lg:grid-cols-2 grid-cols-1  gap-12  py-8">
      <div className="flex items-center justify-center">
        <span className="text-4xl font-medium">
          {" "}
          See what people like you talk about us
        </span>
      </div>
      {/** Tripadvisor travelers review widget **/}
      <div id="TA_selfserveprop184" class="TA_selfserveprop">
        <ul id="34l354rsLI" class="TA_links 9hiJuBUBSt">
          <li id="QlQuAsfo3" class="Byh2gybG6w">
            <a
              target="_blank"
              href="https://www.tripadvisor.com/Hotel_Review-g317074-d6968764-Reviews-The_Palm_Beach_Resort-Gisenyi_Western_Province.html"
            >
              <img
                src="https://www.tripadvisor.com/img/cdsi/img2/branding/v2/Tripadvisor_lockup_horizontal_secondary_registered-11900-2.svg"
                alt="TripAdvisor"
              />
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default TripAdvisorWidget
