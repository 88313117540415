import React from "react"
import { Link } from "gatsby"

const Stay = () => {
  return (
    <div class="stay">
      <div class=" w-full h-full  flex  items-center justify-center ">
        <div class="bg-blur p-10 text-center">
          <span class="text-lg sm:text-md text-white tracking-wide block ">
            STAY ON LAKE KIVU SHORE AND
          </span>
          <span class="text-4xl sm:text-2xl text-white font-medium my-2  tracking-wides block">
            FEEL THE TASTE OF THE TRANQUILITY
          </span>
          <span class="text-lg sm:text-md text-white tracking-wide block ">
            WITH STUNNING PANORAMIC LAKE VIEW
          </span>
          <Link to="/book">
            <button
              class=" focus:outline-none hover:outline-none  my-4 px-5  py-2  items-center justify-center rounded-md bg-yellow-400 text-green-800 font-bold "
              type="submit"
            >
              Come and Stay
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Stay
