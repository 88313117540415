import React from "react"
import { Link } from "gatsby"
const Bungalow = ({ title, description, img }) => {
  return (
    <div class="grid grid-row-1 lg:grid-cols-2 grid-cols-1  gap-12  my-16 ">
      <div class="lg:py-20 py-10  ">
        <div class="divide-y-2 divide-gray-300 divide-solid  divide-opacity-50 inline  py-6">
          <div class="py-6">
            <span class="text-2xl font-medium">{title}</span>
          </div>
          <div class="lg:py-8 md:py-4">
            {" "}
            <span class="text-lg">{description} </span>
          </div>
        </div>

        <Link to="/book">
          <button
            class=" my-4 px-5  py-2  items-center justify-center rounded-md bg-green-800 text-white font-medium capitalize"
            type="submit"
          >
            reserve now
          </button>
        </Link>
      </div>

      <div class="flex items-center justify-center">
        <img src={img} alt={title} class="lg:w-9/12 w-full rounded-lg" />
      </div>
    </div>
  )
}

export default Bungalow
