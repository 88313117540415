import React from "react"
import Bungalow from "./Bungalow"

const bungalows = [
  {
    title: "African Bungalow",
    description:
      "Located in the lower part of the domain, on the shores of Lake Kivu, the bungalows, in typical African style, and decorated with local craft are surrounded by a large garden lined with palm trees and exotic flowers. The  bungalow is ideal for families or friends travelling together who would also like some privacy within the group.",
    url: "",
    img: "/images/african_bungalow.jpg",
  },
  {
    title: "Modern Bungalow",
    description:
      "Situated in the higher part of the resort, the bungalows, built with volcanic stones and  are surrounded by flowers with wonderful perfume and exotic plants. The bungalows are made of one room (single or double), a bathroom with private toilets and a terrace with beautiful views of the lake.",
    url: "",
    img: "/images/modern_bungalow.jpg",
  },
]
const BungalowList = () => {
  return (
    <div class="px-16 my-16">
      {bungalows.map(bungalow => (
        <Bungalow key={bungalow.title} {...bungalow} />
      ))}
    </div>
  )
}

export default BungalowList
