import React from "react"

const Hero = ({ routeRefs }) => {
  return (
    <section class="hero" id="hero">
      <div class=" w-full h-full flex  items-center justify-center ">
        <div class="  p-10 text-center bg-green-800  bg-opacity-50">
          <span class="text-lg sm:text-md text-white tracking-wide block ">
            WELCOME TO
          </span>
          <span class="lg:text-4xl text-2xl text-white font-medium my-1  tracking-wides block">
            THE PALM BEACH RESORT
          </span>
          <span class="lg:text-lg text-md text-white   leading-9 tracking-wide block ">
            RUBAVU-GISENYI
          </span>
        </div>
      </div>

      <div
        class="scroll-down center-me-down sm:mb-20 cursor-pointer"
        onClick={e => {
          e.preventDefault()
          routeRefs.introRef.current.scrollIntoView({
            behavior: "smooth",
            inline: "start",
          })
        }}
      >
        <a>
          <span></span>
          <span></span>
          <span></span>
        </a>
      </div>
    </section>
  )
}

export default Hero
