import React from "react"
import { Layout } from "../components/layout"
import Hero from "../components/Hero"
import Intro from "../components/Intro"
import Stay from "../components/Stay"
import BungalowList from "../components/BungalowList"
import Dinning from "../components/Dinning"
import PropertyGallery from "../components/GalleryProperty"
import Activities from "../components/Activities"
import TripAdvisorWidget from "../components/TripAdvisorWidget"
import { Head } from "../components/commons"

function IndexPage() {
  const introRef = React.useRef(null)
  const stayRef = React.useRef(null)
  const dinningRef = React.useRef(null)
  const activitiesRef = React.useRef(null)
  const exploreRef = React.useRef(null)

  const contactRef = React.useRef(null)

  const routeRefs = {
    introRef,
    stayRef,
    dinningRef,
    activitiesRef,
    exploreRef,
    contactRef,
  }

  return (
    <Layout routeRefs={routeRefs}>
      <Head title="Home" image={{ src: "/images/hero-img.jpg" }} />
      <Hero routeRefs={routeRefs} />
      <section id="intro" ref={introRef}>
        <Intro />
      </section>
      <section id="stay" ref={stayRef}>
        <Stay />
        <BungalowList />
      </section>
      <section id="dinning" ref={dinningRef}>
        <Dinning />
      </section>
      <section id="activities" ref={activitiesRef}>
        <Activities />
      </section>
      <section id="explore" ref={exploreRef}>
        <PropertyGallery />
      </section>

      <section>
        <TripAdvisorWidget />
      </section>

      <section id="contact" ref={contactRef} />
    </Layout>
  )
}

export default IndexPage
