import React from "react"
import { Link } from "gatsby"

const Dinning = () => {
  return (
    <div class="dinning">
      {" "}
      <div class=" w-full h-full  flex  items-center justify-center ">
        <div class="bg-blur p-10 lg:text-center lg:w-1/2 ">
          <p class="text-white  text-xl lg:text-2xl font-light">
            The restaurant offers you to taste the local specialties such as
            fish (tilapia and isambaza) or goat skewers and grilled beef on a
            bed of makala.
          </p>

          <p class="text-white text-xl lg:text-2xl font-light pt-7">
            Opportunity to dine in the restaurant or on the shores of Lake Kivu
            and enjoy the beautiful sunset.
          </p>

          <Link to="/menu">
            <div class="flex items-center justify-center py-6">
              <button
                class=" outline-none focus:outline-none hover:outline-none my-4 px-5  py-2  items-center justify-center rounded-md bg-yellow-400 text-green-800 font-bold capitalize"
                type="submit"
              >
                view menu
              </button>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Dinning
